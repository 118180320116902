body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.active {
  color: #12c7e0;
}

a {
  color: inherit;
  text-decoration: none;
}

/* NOT FOUND PAGE */

.error-container {
  text-align: center;
  font-size: 106px;
  font-family: "Catamaran", sans-serif;
  font-weight: 800;
  margin: 70px 15px;
}

.error-container > span {
  display: inline-block;
  position: relative;
}

.error-container > span.four {
  width: 136px;
  height: 43px;
  border-radius: 999px;
  background: linear-gradient(140deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 43%, transparent 44%, transparent 100%),
    linear-gradient(
      105deg,
      transparent 0%,
      transparent 40%,
      rgba(0, 0, 0, 0.06) 41%,
      rgba(0, 0, 0, 0.07) 76%,
      transparent 77%,
      transparent 100%
    ),
    linear-gradient(to right, #9b2d3e, #370009);
}

.error-container > span.four:before,
.error-container > span.four:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 999px;
}

.error-container > span.four:before {
  width: 43px;
  height: 156px;
  left: 60px;
  bottom: -43px;
  background: linear-gradient(128deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 40%, transparent 41%, transparent 100%),
    linear-gradient(116deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.07) 50%, transparent 51%, transparent 100%),
    linear-gradient(to top, #9b2d3e, #821123, #690011, #50000d, #370009);
}

.error-container > span.four:after {
  width: 137px;
  height: 43px;
  transform: rotate(-49.5deg);
  left: -18px;
  bottom: 36px;
  background: linear-gradient(to right, #9b2d3e, #821123, #690011, #50000d, #370009);
}

.error-container > span.zero {
  vertical-align: text-top;
  width: 156px;
  height: 156px;
  border-radius: 999px;
  background: linear-gradient(-45deg, transparent 0%, rgba(0, 0, 0, 0.06) 50%, transparent 51%, transparent 100%),
    linear-gradient(to top right, #9b2d3e, #9b2d3e, #821123, #690011, #50000d, #370009, #370009);
  overflow: hidden;
  animation: bgshadow 5s infinite;
}

.error-container > span.zero:before {
  content: "";
  display: block;
  position: absolute;
  transform: rotate(45deg);
  width: 90px;
  height: 90px;
  background-color: transparent;
  left: 0px;
  bottom: 0px;
  background: linear-gradient(
      95deg,
      transparent 0%,
      transparent 8%,
      rgba(0, 0, 0, 0.07) 9%,
      transparent 50%,
      transparent 100%
    ),
    linear-gradient(
      85deg,
      transparent 0%,
      transparent 19%,
      rgba(0, 0, 0, 0.05) 20%,
      rgba(0, 0, 0, 0.07) 91%,
      transparent 92%,
      transparent 100%
    );
}

.error-container > span.zero:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 999px;
  width: 70px;
  height: 70px;
  left: 43px;
  bottom: 43px;
  background: #fdfaf5;
  box-shadow: -2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.screen-reader-text {
  position: absolute;
  top: -9999em;
  left: -9999em;
}

@keyframes bgshadow {
  0% {
    box-shadow: inset -160px 160px 0px 5px rgba(0, 0, 0, 0.4);
  }

  45% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }

  55% {
    box-shadow: inset 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
  }

  100% {
    box-shadow: inset 160px -160px 0px 5px rgba(0, 0, 0, 0.4);
  }
}

.super-app-theme--header {
  white-space: break-spaces !important;
  align-items: center !important;
  overflow: auto !important;
}

/* demo stuff */
.zoom-area {
  max-width: 490px;
  margin: 30px auto 30px;
  font-size: 19px;
  text-align: center;
}
/* END NOT FOUND PAGE */

body {
  background-color: #ecf0f1;
  /* background-color: white; */
}

section.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5%;
  height: 100vh;
}

section.notFound h1 {
  color: red;
  font-size: 100px;
}

section.notFound h2 {
  font-size: 50px;
}

section.notFound h1,
h2,
h3 {
  margin-bottom: 40px;
}

div.text {
  height: 50vh;
}

div.text a {
  text-decoration: none;
  margin-right: 20px;
}

div.text a:hover {
  color: red;
  text-decoration: underline;
}

*::-webkit-scrollbar {
  width: 0.35em;
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

@media only screen and (max-width: 768px) {
  section.notFound {
    flex-direction: column;
    justify-content: space-around;
  }

  section.notFound div.img img {
    width: 70vw;
    height: auto;
  }

  section.notFound h1 {
    font-size: 50px;
  }

  section.notFound h2 {
    font-size: 25px;
  }

  div.text a:active {
    color: red;
    text-decoration: underline;
  }
}

.MuiDataGrid-cell {
  overflow: auto !important;
  white-space: normal !important;
}

.MuiDataGrid-cell .MuiBadge-badge {
  white-space: nowrap !important;
}

.MuiDayPicker-weekContainer .MuiPickersDay-hiddenDaySpacingFiller {
  width: 36px !important;
}
